// noinspection JSUnusedGlobalSymbols, JSValidateJSDoc

import React, { ReactElement, ReactNode } from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import {
    FooterComponent as SourceFooterComponent,
} from 'SourceComponent/Footer/Footer.component';
import {FooterRenderColumnItem} from 'SourceComponent/Footer/Footer.type';

import {COLUMN_MAP} from './Footer.config';

import './Footer.override.style';

/** @namespace Lebonvin/PwaBase/Component/Footer/Component */
export class FooterComponent extends SourceFooterComponent {
    // Override the copyright rendering to remove the "Powered by ScandiPWA".
    renderCopyrightContent(): ReactElement {
        const {
            copyright,
            // @ts-ignore
            canDisplayFooterColumns,
        } = this.props;

        return (
            <ContentWrapper
              mix={ { block: 'Footer', elem: 'CopyrightContentWrapper' } }
              wrapperMix={ {
                  block: 'Footer',
                  elem: 'CopyrightContent',
                  mods: { Small: !canDisplayFooterColumns(COLUMN_MAP) },
              } }
              label=""
            >
                { /* TODO: Removing this span changes the styling, might want to look at that. */ }
                <span block="Footer" elem="Copyright">
                    { copyright }
                </span>
            </ContentWrapper>
        );
    }

    // Override the content render to null the block if there is no content
    // (e.g. secure login screen, or where there is no data, like base theme.)
    renderContent(): ReactNode {
        const { footer_content: { footer_cms = undefined } = {} } = window.contentConfiguration || {};
        // @ts-ignore
        const { canDisplayFooterColumns } = this.props;

        if (footer_cms) {
            // False positive error about the return type
            // @ts-ignore
            return this.renderCmsBlockWrapper();
        }

        if (!canDisplayFooterColumns(COLUMN_MAP)) {
            return null;
        }

        return (
            <div block="Footer" elem="Content">
                { this.renderColumns() }
            </div>
        );
    }

    // ADDITION: new helper function for displaying non-link items.
    renderColumnItemBlock(
        item: FooterRenderColumnItem,
        i: number,
    ): ReactElement {
        const { src, title } = item;

        return (
            <div
              block="Footer"
              elem="ColumnItem"
              mods={ src ? { type: 'image', static: true } : { static: true } }
              key={ i }
              aria-label={ title }
            >
                { this.renderColumnItemContent(src, title) }
            </div>
        );
    }

    // Override item rendering, to allow for pure text elements without needing
    // to implement custom render functions.
    renderColumnItem(item: FooterRenderColumnItem, i: number): ReactNode {
        const { render } = item;

        if (render && render in this.renderMap) {
            return this.renderMap[render as keyof typeof this.renderMap].render();
        }

        if (item.href) {
            return this.renderColumnItemLink(item, i);
        }

        return this.renderColumnItemBlock(item, i);
    }
}

export default FooterComponent;
