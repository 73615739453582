/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/* eslint-disable @typescript-eslint/naming-convention */
// noinspection JSUnusedGlobalSymbols

import { FooterContainerPlugin } from
    '@lebonvin/pwa-base/packages/versatile-components/src/plugin/Footer.container.plugin';

import { FooterContainer } from 'Component/Footer/Footer.container';
import {
    FooterContainerMapStateProps,
    FooterContainerPropsKeys,
} from 'Component/Footer/Footer.type';
import { isSignedIn } from 'Util/Auth/IsSignedIn';
import { RootState } from 'Util/Store/Store.type';

import {
    PluginFooterComponentProps,
    PluginFooterContainerMapStateProps,
    PluginFooterContainerPropsKeys,
} from './Footer.pluginTypes';

export class FooterContainerSecurePlugin extends FooterContainerPlugin {
    canDisplayFooterColumns(map: Array<unknown>): boolean {
        if (!isSignedIn()) {
            return false;
        }

        return super.canDisplayFooterColumns(map);
    }
}

const { containerFunctions } = new FooterContainerSecurePlugin();

const mapStateToProps = (
    args: [RootState],
    callback: (state: RootState) => FooterContainerMapStateProps,
    _instance: unknown,
): PluginFooterContainerMapStateProps => {
    const [state] = args;

    return {
        ...callback(...args),
        isSignedIn: state.MyAccountReducer.isSignedIn,
    };
};

const containerProps = (
    args: [],
    callback: () => Pick<PluginFooterComponentProps, FooterContainerPropsKeys>,
    instance: FooterContainer,
): Pick<PluginFooterComponentProps, PluginFooterContainerPropsKeys> => {
    // @ts-ignore
    const { isSignedIn } = instance.props;

    return {
        ...callback(),
        isSignedIn,
    };
};

export default {
    'Component/Footer/Container': {
        'member-property': {
            containerFunctions,
        },
        'member-function': {
            containerProps,
        },
    },
    'Component/Footer/Container/mapStateToProps': {
        function: mapStateToProps,
    },
};
