/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/* eslint-disable @typescript-eslint/naming-convention */
// noinspection JSUnusedGlobalSymbols

import { FooterContainerFunctions } from 'SourceComponent/Footer/Footer.type';

export class FooterContainerPlugin {
    containerFunctions = (originalMember: FooterContainerFunctions, instance: unknown) => ({
        ...originalMember,
        canDisplayFooterColumns: this.canDisplayFooterColumns.bind(instance),
    });

    canDisplayFooterColumns(map: Array<unknown>): boolean {
        return map.length > 0;
    }
}

const { containerFunctions } = new FooterContainerPlugin();

export default {
    'Component/Footer/Container': {
        'member-property': {
            containerFunctions,
        },
    },
};
