// noinspection JSUnusedGlobalSymbols

import {
    NEWSLETTER_COLUMN,
    RENDER_NEWSLETTER,
} from 'SourceComponent/Footer/Footer.config';

export {
    RENDER_NEWSLETTER,
    NEWSLETTER_COLUMN,
};

export const COLUMN_MAP = [];
