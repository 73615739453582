// noinspection JSUnusedGlobalSymbols, JSValidateJSDoc

import AddIcon from 'Component/AddIcon';
import { FieldType } from 'Component/Field/Field.config';
import { FieldReactEvents } from 'Component/Field/Field.type';
import MinusIcon from 'Component/MinusIcon';
import {
    FieldNumberWithControlsComponent as SourceFieldNumberWithControlsComponent,
} from 'SourceComponent/FieldNumberWithControls/FieldNumberWithControls.component';
import { ReactElement } from 'Type/Common.type';
import { DEFAULT_MAX_PRODUCTS } from 'Util/Product/Product.type';

/** @namespace Lebonvin/PwaBase/Component/FieldNumberWithControls/Component */
export class FieldNumberWithControlsComponent extends SourceFieldNumberWithControlsComponent {
    render(): ReactElement {
        const {
            attr,
            attr: {
                min: attrMin = 1,
                max: attrMax = DEFAULT_MAX_PRODUCTS,
                step: attrStep = 1,
            },
            value,
            events,
            setRef,
            stateValue,
            handleValueChange,
            isDisabled,
        } = this.props;

        // Duplicated guard / conversion from the original component. Appears to
        // be type-coercing the variable, but not clear why stateValue is
        // included, because it is never referenced again?  Initial render fix?
        const numberValue: number = +value || +stateValue;

        // Additions limits, including explicit type-coercion.
        const min: number = Number(attrMin);
        const max: number = Number(attrMax);
        const step: number = Number(attrStep);
        const lowerLimit: number = min % step === 0 ? min : (min + step - (min % step));
        const upperLimit: number = Math.floor(max / step) * step;

        // Functions for handling the actual work.
        function increment() {
            const adjusted = numberValue + step - (numberValue % step);
            const ceiling = adjusted > upperLimit ? upperLimit : adjusted;
            const change = adjusted < lowerLimit ? numberValue : ceiling;
            handleValueChange(change);
        }

        function decrement() {
            const adjusted = numberValue - (numberValue % step === 0 ? Number(step) : numberValue % step);
            const ceiling = adjusted > upperLimit ? upperLimit : adjusted;
            const change = adjusted < lowerLimit ? numberValue : ceiling;
            handleValueChange(change);
        }

        return (
            <>
                <input
                  ref={ (elem) => setRef(elem) }
                    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                  { ...attr }
                    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                  { ...events as FieldReactEvents<HTMLInputElement> }
                  value={ value }
                  type={ FieldType.NUMBER }
                  readOnly
                  aria-label={ __('Value') }
                  disabled={ isDisabled }
                />
                <button
                  disabled={ numberValue >= upperLimit || isDisabled }
                  onClick={ increment }
                  aria-label={ __('Add') }
                  type={ FieldType.BUTTON }
                >
                    <AddIcon block="SubtractButton" isPrimary />
                </button>
                <button
                  disabled={ numberValue <= lowerLimit || isDisabled }
                  onClick={ decrement }
                  aria-label={ __('Subtract') }
                  type={ FieldType.BUTTON }
                >
                    <MinusIcon block="AddButton" isPrimary />
                </button>
            </>
        );
    }
}

export default FieldNumberWithControlsComponent;
