// noinspection JSValidateJSDoc

import { ReactNode } from 'react';

import {
    FooterComponent as BaseFooterComponent,
} from 'BaseComponent/Footer/Footer.component';

/** @namespace Lebonvin/PwaSecure/Component/Footer/Component */
export class FooterComponent extends BaseFooterComponent {
    // Override to remove the "render when visible" block, since that is
    // preventing rendering in the login required router.
    render(): ReactNode {
        // @ts-ignore
        const {isSignedIn} = this.props;

        if (isSignedIn) {
            return super.render();
        }

        return (
            <footer block="Footer" aria-label="Footer">
                { this.renderContent() }
                { this.renderCopyrightContent() }
            </footer>
        );
    }
}

export default FooterComponent;
