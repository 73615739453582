// noinspection JSUnusedGlobalSymbols

export {
    NEWSLETTER_COLUMN,
    RENDER_NEWSLETTER,
} from 'SecureComponent/Footer/Footer.config';

export const COLUMN_MAP = [
    {
        title: __('Orders'),
        items: [
            {
                href: '/sales/order/history',
                title: __('Order History'),
            },
            {
                href: '/tarifs',
                title: __('Price Information & History'),
            },
        ],
    },
    {
        title: __('Contact'),
        items: [
            // {
            //     href: '/contact',
            //     title: __('Contact Us'),
            // },
            {
                // href: '/contact',
                title: __('Telephone: 0114 256 0090'),
            },
        ],
    },
    {
        title: __('Additional info'),
        items: [
            {
                href: '/delivery',
                title: __('Delivery Information'),
            },
            {
                href: '/privacy-policy-cookie-restriction-mode',
                title: __('Privacy Policy'),
            },
        ],
    },
];
