/* eslint-disable @typescript-eslint/naming-convention */
// noinspection JSUnusedGlobalSymbols

import FooterComponent from 'Component/Footer/Footer.component';
import {
    FooterComponentProps,
} from 'Component/Footer/Footer.type';

import { PluginFooterComponentProps } from './Footer.pluginTypes';

const shouldComponentUpdate = (
    args: [PluginFooterComponentProps],
    callback: (nextProps: FooterComponentProps) => boolean,
    instance: FooterComponent,
): boolean => {
    const [nextProps] = args;
    const { isSignedIn: nextIsSignedIn } = nextProps;
    // @ts-ignore
    const { isSignedIn: currentIsSignedIn } = instance.props;

    return callback.apply(instance, args) || currentIsSignedIn !== nextIsSignedIn;
};

export default {
    'Component/Footer/Component': {
        'member-function': {
            shouldComponentUpdate,
        },
    },
};
